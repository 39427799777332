import {createRouter, createWebHistory} from "vue-router";
import {userUserStore} from "../stores/user";
import Layout from "../layout/Layout.vue";
import ErrorCarga from "../pages/ErrorCarga/ErrorCarga.vue";

const Home = async () => {
  try {
    return await import("../pages/Home/Home.vue");
  } catch (e) {
    return ErrorCarga;
  }
};
const Perfil = async () => {
  try {
    return await import("../pages/Perfil/Perfil.vue");
  } catch (e) {
    return ErrorCarga;
  }
};
const Pagar = async () => {
  try {
    return await import("../pages/Pagar/Pagar.vue");
  } catch (e) {
    return ErrorCarga;
  }
};
const Login = async () => {
  try {
    return await import("../pages/Login/Login.vue");
  } catch (e) {
    return ErrorCarga;
  }
};
const LoginMagicLink = async () => {
  try {
    return await import("../pages/LoginMagicLink/LoginMagicLink.vue");
  } catch (e) {
    return ErrorCarga;
  }
};
const Logout = async () => {
  try {
    return await import("../pages/Logout/Logout.vue");
  } catch (e) {
    return ErrorCarga;
  }
};
const Registro = async () => {
  try {
    return await import("../pages/Registro/Registro.vue");
  } catch (e) {
    return ErrorCarga;
  }
};
const Servicios = async () => {
  try {
    return await import("../pages/Servicios/Servicios.vue");
  } catch (e) {
    return ErrorCarga;
  }
};
const ServiciosRubros = async () => {
  try {
    return await import("../pages/ServiciosRubros/ServiciosRubros.vue");
  } catch (e) {
    return ErrorCarga;
  }
};
const ProductosRubros = async () => {
  try {
    return await import("../pages/ProductosRubros/ProductosRubros.vue");
  } catch (e) {
    return ErrorCarga;
  }
};
const ServiciosListasPrecios = async () => {
  try {
    return await import("../pages/ServiciosListasPrecios/ServiciosListasPrecios.vue");
  } catch (e) {
    return ErrorCarga;
  }
};
const TasasIva = async () => {
  try {
    return await import("../pages/TasasIva/TasasIva.vue");
  } catch (e) {
    return ErrorCarga;
  }
};
const Monedas = async () => {
  try {
    return await import("../pages/Monedas/Monedas.vue");
  } catch (e) {
    return ErrorCarga;
  }
};
const ImportadorXML = async () => {
  try {
    return await import("../pages/ImportadorXML/ImportadorXML.vue");
  } catch (e) {
    return ErrorCarga;
  }
};
const CFEs = async () => {
  try {
    return await import("../pages/CFEs/CFEs.vue");
  } catch (e) {
    return ErrorCarga;
  }
};
const CFEsRecibidos = async () => {
  try {
    return await import("../pages/CFEsRecibidos/CFEsRecibidos.vue");
  } catch (e) {
    return ErrorCarga;
  }
};
const CFEReportesDiarios = async () => {
  try {
    return await import("../pages/CFEReportesDiarios/CFEReportesDiarios.vue");
  } catch (e) {
    return ErrorCarga;
  }
};
const FirmaElectronica = async () => {
  try {
    return await import("../pages/FirmaElectronica/FirmaElectronica.vue");
  } catch (e) {
    return ErrorCarga;
  }
};
const DeclaracionBeneficio = async () => {
  try {
    return await import("../pages/DeclaracionBeneficio/DeclaracionBeneficio.vue");
  } catch (e) {
    return ErrorCarga;
  }
};
const Clientes = async () => {
  try {
    return await import("../pages/Clientes/Clientes.vue");
  } catch (e) {
    return ErrorCarga;
  }
};
const ClientesCuentasCorrientes = async () => {
  try {
    return await import("../pages/ClientesCuentasCorrientes/ClientesCuentasCorrientes.vue");
  } catch (e) {
    return ErrorCarga;
  }
};
const Proveedores = async () => {
  try {
    return await import("../pages/Proveedores/Proveedores.vue");
  } catch (e) {
    return ErrorCarga;
  }
};
const ProveedoresCuentasCorrientes = async () => {
  try {
    return await import("../pages/ProveedoresCuentasCorrientes/ProveedoresCuentasCorrientes.vue");
  } catch (e) {
    return ErrorCarga;
  }
};
const Usuarios = async () => {
  try {
    return await import("../pages/Usuarios/Usuarios.vue");
  } catch (e) {
    return ErrorCarga;
  }
};
const UsuariosPosHandy = async () => {
  try {
    return await import("../pages/UsuariosPosHandy/UsuariosPosHandy.vue");
  } catch (e) {
    return ErrorCarga;
  }
};
const Locales = async () => {
  try {
    return await import("../pages/Locales/Locales.vue");
  } catch (e) {
    return ErrorCarga;
  }
};
const CajaMediosPago = async () => {
  try {
    return await import("../pages/CajaMediosPago/CajaMediosPago.vue");
  } catch (e) {
    return ErrorCarga;
  }
};
const CajaMediosCobro = async () => {
  try {
    return await import("../pages/CajaMediosCobro/CajaMediosCobro.vue");
  } catch (e) {
    return ErrorCarga;
  }
};
const AceptarInvitacion = async () => {
  try {
    return await import("../pages/AceptarInvitacion/AceptarInvitacion.vue");
  } catch (e) {
    return ErrorCarga;
  }
};
const SoporteFixed = async () => {
  try {
    return await import("../pages/SoporteFixed/SoporteFixed.vue");
  } catch (e) {
    return ErrorCarga;
  }
};
const OpcionesGenerales = async () => {
  try {
    return await import("../pages/OpcionesGenerales/OpcionesGenerales.vue");
  } catch (e) {
    return ErrorCarga;
  }
};
const Unidades = async () => {
  try {
    return await import("../pages/Unidades/Unidades.vue");
  } catch (e) {
    return ErrorCarga;
  }
};
const ServiciosListasPreciosEditarPrecios = async () => {
  try {
    return await import("../pages/ServiciosListasPrecios/components/ServiciosListasPreciosEditarPrecios.vue");
  } catch (e) {
    return ErrorCarga;
  }
};
const CentroCostos = async () => {
  try {
    return await import("../pages/CentroCostos/CentroCostos.vue");
  } catch (e) {
    return ErrorCarga;
  }
};
const CuentaAjena = async () => {
  try {
    return await import("../pages/CuentaAjena/CuentaAjena.vue");
  } catch (e) {
    return ErrorCarga;
  }
};
const UsuariosRoles = async () => {
  try {
    return await import("../pages/UsuariosRoles/UsuariosRoles.vue");
  } catch (e) {
    return ErrorCarga;
  }
};
const Productos = async () => {
  try {
    return await import("../pages/Productos/Productos.vue");
  } catch (e) {
    return ErrorCarga;
  }
};
const ProductosListasPreciosEditarPrecios = async () => {
  try {
    return await import("../pages/ProductosListasPrecios/components/ProductosListasPreciosEditarPrecios.vue");
  } catch (e) {
    return ErrorCarga;
  }
};
const ProductosListasPrecios = async () => {
  try {
    return await import("../pages/ProductosListasPrecios/ProductosListasPrecios.vue");
  } catch (e) {
    return ErrorCarga;
  }
};
const Remitos = async () => {
  try {
    return await import("../pages/Remitos/Remitos.vue");
  } catch (e) {
    return ErrorCarga;
  }
};
const Stock = async () => {
  try {
    return await import("../pages/Stock/Stock.vue");
  } catch (e) {
    return ErrorCarga;
  }
};
const Caja = async () => {
  try {
    return await import("../pages/Caja/Caja.vue");
  } catch (e) {
    return ErrorCarga;
  }
};
const Compras = async () => {
  try {
    return await import("../pages/Compras/Compras.vue");
  } catch (e) {
    return ErrorCarga;
  }
};
const Ventas = async () => {
  try {
    return await import("../pages/Ventas/Ventas.vue");
  } catch (e) {
    return ErrorCarga;
  }
};
const ComprasRubros = async () => {
  try {
    return await import("../pages/ComprasRubros/ComprasRubros.vue");
  } catch (e) {
    return ErrorCarga;
  }
};
const ComprasAgregar = async () => {
  try {
    return await import("../pages/Compras/components/ComprasAgregar.vue");
  } catch (e) {
    return ErrorCarga;
  }
};
const VentasAgregar = async () => {
  try {
    return await import("../pages/Ventas/components/VentasAgregar.vue");
  } catch (e) {
    return ErrorCarga;
  }
};
const FormasDePagoPlazos = async () => {
  try {
    return await import("../pages/FormasDePagoPlazos/FormasDePagoPlazos.vue");
  } catch (e) {
    return ErrorCarga;
  }
};
const Resguardos = async () => {
  try {
    return await import("../pages/Resguardos/Resguardos.vue");
  } catch (e) {
    return ErrorCarga;
  }
};
const ResguardosRecibidos = async () => {
  try {
    return await import("../pages/Resguardos/ResguardosRecibidos.vue");
  } catch (e) {
    return ErrorCarga;
  }
};
const OpcionesCompras = async () => {
  try {
    return await import("../pages/OpcionesCompras/OpcionesCompras.vue");
  } catch (e) {
    return ErrorCarga;
  }
};
const OpcionesVentas = async () => {
  try {
    return await import("../pages/OpcionesVentas/OpcionesVentas.vue");
  } catch (e) {
    return ErrorCarga;
  }
};
const Importador = async () => {
  try {
    return await import("../pages/Importador/Importador.vue");
  } catch (e) {
    return ErrorCarga;
  }
};
const EstadisticasVenta = async () => {
  return await import("../pages/EstadisticasVenta/EstadisticasVenta.vue");
  //   try {
  //   return await import("../pages/EstadisticasVenta/EstadisticasVenta.vue");
  // } catch (e) {
  //   return ErrorCarga;
  // }
};
const EstadisticasCompra = async () => {
  try {
    return await import("../pages/EstadisticasCompra/EstadisticasCompra.vue");
  } catch (e) {
    return ErrorCarga;
  }
};
const EstadisticasCentroCostos = async () => {
  try {
    return await import("../pages/EstadisticasCentroCostos/EstadisticasCentroCostos.vue");
  } catch (e) {
    return ErrorCarga;
  }
};
const CAE = async () => {
  try {
    return await import("../pages/CAE/CAE.vue");
  } catch (e) {
    return ErrorCarga;
  }
};
const ConstanciaContigencia = async () => {
  try {
    return await import("../pages/ConstanciaContingencia/ConstanciaContingencia.vue");
  } catch (e) {
    return ErrorCarga;
  }
};
const eBoletaCompra = async () => {
  try {
    return await import("../pages/eBoletaCompra/eBoletaCompra.vue");
  } catch (e) {
    return ErrorCarga;
  }
};
const ClientesRecibos = async () => {
  try {
    return await import("../pages/ClientesRecibos/ClietesRecibos.vue");
  } catch (e) {
    return ErrorCarga;
  }
};
const ProveedoresRecibos = async () => {
  try {
    return await import("../pages/ProveedoresRecibos/ProveedoresRecibos.vue");
  } catch (e) {
    return ErrorCarga;
  }
};
const CFEEnvioMail = async () => {
  try {
    return await import("../pages/CFEEnvioMail/CFEEnvioMail.vue");
  } catch (e) {
    return ErrorCarga;
  }
};
const RecibirTokenOAuthGoogle = async () => {
  try {
    return await import("../pages/RecibirTokenOAuthGoogle/RecibirTokenOAuthGoogle.vue");
  } catch (e) {
    return ErrorCarga;
  }
};
const RecibirTokenOAuthGoogleLectorMails = async () => {
  try {
    return await import("../pages/RecibirTokenOAuthGoogleLectorMails/RecibirTokenOAuthGoogleLectorMails.vue");
  } catch (e) {
    return ErrorCarga;
  }
};
const OpcionesCotizaciones = async () => {
  try {
    return await import("../pages/OpcionesCotizaciones/OpcionesCotizaciones.vue");
  } catch (e) {
    return ErrorCarga;
  }
};
const Documentos = async () => {
  try {
    return await import("../pages/Documentos/Documentos.vue");
  } catch (e) {
    return ErrorCarga;
  }
};
const HandyTransacciones = async () => {
  try {
    return await import("../pages/HandyTransacciones/HandyTransacciones.vue");
  } catch (e) {
    return ErrorCarga;
  }
};
const FiservTransacciones = async () => {
  try {
    return await import("../pages/FiservTransacciones/FiservTransacciones.vue");
  } catch (e) {
    return ErrorCarga;
  }
};
const RegistroHandy = async () => {
  try {
    return await import("../pages/RegistroHandy/RegistroHandy.vue");
  } catch (e) {
    return ErrorCarga;
  }
};
const AnalisisDeuda = async () => {
  try {
    return await import("../pages/AnalisisDeuda/AnalisisDeuda.vue");
  } catch (e) {
    return ErrorCarga;
  }
};
const FormularioConfiguracion = async () => {
  try {
    return await import("../pages/FormularioConfiguracion/FormularioConfiguracion.vue");
  } catch (e) {
    return ErrorCarga;
  }
};

// import Home from "../pages/Home/Home.vue";
// import Perfil from "../pages/Perfil/Perfil.vue";
// import Login from "../pages/Login/Login.vue";
// import LoginMagicLink from "../pages/LoginMagicLink/LoginMagicLink.vue";
// import Logout from "../pages/Logout/Logout.vue";
// import Registro from "../pages/Registro/Registro.vue";
// import Servicios from "../pages/Servicios/Servicios.vue";
// import ServiciosRubros from "../pages/ServiciosRubros/ServiciosRubros.vue";
// import ProductosRubros from "../pages/ProductosRubros/ProductosRubros.vue";
// import ServiciosListasPrecios from "../pages/ServiciosListasPrecios/ServiciosListasPrecios.vue";
// import TasasIva from "../pages/TasasIva/TasasIva.vue";
// import Monedas from "../pages/Monedas/Monedas.vue";
// import CFEs from "../pages/CFEs/CFEs.vue";
// import CFEsRecibidos from "../pages/CFEsRecibidos/CFEsRecibidos.vue";
// import CFEReportesDiarios from "../pages/CFEReportesDiarios/CFEReportesDiarios.vue";
// import FirmaElectronica from "../pages/FirmaElectronica/FirmaElectronica.vue";
// import DeclaracionBeneficio from "../pages/DeclaracionBeneficio/DeclaracionBeneficio.vue";
// import Clientes from "../pages/Clientes/Clientes.vue";
// import ClientesCuentasCorrientes from "../pages/ClientesCuentasCorrientes/ClientesCuentasCorrientes.vue";
// import Proveedores from "../pages/Proveedores/Proveedores.vue";
// import ProveedoresCuentasCorrientes from "../pages/ProveedoresCuentasCorrientes/ProveedoresCuentasCorrientes.vue";
// import Usuarios from "../pages/Usuarios/Usuarios.vue";
// import UsuariosPosHandy from "../pages/UsuariosPosHandy/UsuariosPosHandy.vue";
// import Locales from "../pages/Locales/Locales.vue";
// import CajaMediosPago from "../pages/CajaMediosPago/CajaMediosPago.vue";
// import CajaMediosCobro from "../pages/CajaMediosCobro/CajaMediosCobro.vue";
// import AceptarInvitacion from "../pages/AceptarInvitacion/AceptarInvitacion.vue";
// import SoporteFixed from "../pages/SoporteFixed/SoporteFixed.vue";
// import OpcionesGenerales from "../pages/OpcionesGenerales/OpcionesGenerales.vue";
// import Unidades from "../pages/Unidades/Unidades.vue";
// import ServiciosListasPreciosEditarPrecios from "../pages/ServiciosListasPrecios/components/ServiciosListasPreciosEditarPrecios.vue";
// import CentroCostos from "../pages/CentroCostos/CentroCostos.vue";
// import CuentaAjena from "../pages/CuentaAjena/CuentaAjena.vue";
// import UsuariosRoles from "../pages/UsuariosRoles/UsuariosRoles.vue";
// import Productos from "../pages/Productos/Productos.vue";
// import ProductosListasPreciosEditarPrecios from "../pages/ProductosListasPrecios/components/ProductosListasPreciosEditarPrecios.vue";
// import ProductosListasPrecios from "../pages/ProductosListasPrecios/ProductosListasPrecios.vue";
// import Remitos from "../pages/Remitos/Remitos.vue";
// import Stock from "../pages/Stock/Stock.vue";
// import Caja from "../pages/Caja/Caja.vue";
// import Compras from "../pages/Compras/Compras.vue";
// import Ventas from "../pages/Ventas/Ventas.vue";
// import ComprasRubros from "../pages/ComprasRubros/ComprasRubros.vue";
// import ComprasAgregar from "../pages/Compras/components/ComprasAgregar.vue";
// import VentasAgregar from "../pages/Ventas/components/VentasAgregar.vue";
// import FormasDePagoPlazos from "../pages/FormasDePagoPlazos/FormasDePagoPlazos.vue";
// import Resguardos from "../pages/Resguardos/Resguardos.vue";
// import ResguardosRecibidos from "../pages/Resguardos/ResguardosRecibidos.vue";
// import OpcionesCompras from "../pages/OpcionesCompras/OpcionesCompras.vue";
// import OpcionesVentas from "../pages/OpcionesVentas/OpcionesVentas.vue";
// import Importador from "../pages/Importador/Importador.vue";
// import EstadisticasVenta from "../pages/EstadisticasVenta/EstadisticasVenta.vue";
// import EstadisticasCompra from "../pages/EstadisticasCompra/EstadisticasCompra.vue";
// import EstadisticasCentroCostos from "../pages/EstadisticasCentroCostos/EstadisticasCentroCostos.vue";
// import CAE from "../pages/CAE/CAE.vue";
// import ConstanciaContigencia from "../pages/ConstanciaContingencia/ConstanciaContingencia.vue";
// import eBoletaCompra from "../pages/eBoletaCompra/eBoletaCompra.vue";
// import ClientesRecibos from "../pages/ClientesRecibos/ClietesRecibos.vue";
// import ProveedoresRecibos from "../pages/ProveedoresRecibos/ProveedoresRecibos.vue";
// import CFEEnvioMail from "../pages/CFEEnvioMail/CFEEnvioMail.vue";
// import RecibirTokenOAuthGoogle from "../pages/RecibirTokenOAuthGoogle/RecibirTokenOAuthGoogle.vue";
// import RecibirTokenOAuthGoogleLectorMails from "../pages/RecibirTokenOAuthGoogleLectorMails/RecibirTokenOAuthGoogleLectorMails.vue";
// import OpcionesCotizaciones from "../pages/OpcionesCotizaciones/OpcionesCotizaciones.vue";
// import Documentos from "../pages/Documentos/Documentos.vue";
// import HandyTransacciones from "../pages/HandyTransacciones/HandyTransacciones.vue";
// import FiservTransacciones from "../pages/FiservTransacciones/FiservTransacciones.vue";
// import RegistroHandy from "../pages/RegistroHandy/RegistroHandy.vue";
// import FormularioConfiguracion from "../pages/FormularioConfiguracion/FormularioConfiguracion.vue";

const routes = [
  {
    path: "/",
    name: "inicio",
    component: Layout,
    meta: {
      title: "Home"
    },
    children: [
      {
        path: "",
        name: "home",
        component: Home
      },
      {
        path: "perfil",
        name: "perfil",
        component: Perfil
      },
      {
        path: "pagar",
        name: "Pagar",
        component: Pagar
      },
      {
        path: "formulario-configuracion",
        name: "formulario-configuracion",
        component: FormularioConfiguracion
      },
      {
        path: "caja",
        children: [
          {
            path: "",
            name: "caja",
            component: Caja
          },
          {
            path: "medios-pago",
            children: [
              {
                path: "",
                name: "cajaMediosPago",
                component: CajaMediosPago
              }
            ]
          },
          {
            path: "medios-cobro",
            children: [
              {
                path: "",
                name: "cajaMediosCobro",
                component: CajaMediosCobro
              }
            ]
          }
        ]
      },
      {
        path: "servicios",
        children: [
          {
            path: "",
            name: "servicios",
            component: Servicios
          },
          {
            path: "rubros",
            children: [
              {
                path: "",
                name: "serviciosRubros",
                component: ServiciosRubros
              }
            ]
          },
          {
            path: "listas-precios",
            children: [
              {
                path: "",
                name: "serviciosListasPrecios",
                component: ServiciosListasPrecios
              },
              {
                path: `editar-precios/:idLista`,
                name: "serviciosListasPreciosEditarPrecios",
                component: ServiciosListasPreciosEditarPrecios,
                props: true
              }
            ]
          }
        ]
      },
      {
        path: "productos",
        children: [
          {
            path: "",
            name: "productos",
            component: Productos
          },
          {
            path: "rubros",
            children: [
              {
                path: "",
                name: "productosRubros",
                component: ProductosRubros
              }
            ]
          },
          {
            path: "listas-precios",
            children: [
              {
                path: "",
                name: "productosListasPrecios",
                component: ProductosListasPrecios
              },
              {
                path: `editar-precios/:idLista`,
                name: "productosListasPreciosEditarPrecios",
                component: ProductosListasPreciosEditarPrecios,
                props: true
              }
            ]
          }
        ]
      },
      {
        path: "importador",
        name: "importador",
        component: Importador
      },
      {
        path: "handyTransacciones",
        name: "handyTransacciones",
        component: HandyTransacciones
      },
      {
        path: "fiservTransacciones",
        name: "fiservTransacciones",
        component: FiservTransacciones
      },
      {
        path: "remitos",
        children: [
          {
            path: "",
            name: "remitos",
            component: Remitos
          }
        ]
      },
      {
        path: "stock",
        children: [
          {
            path: "",
            name: "stock",
            component: Stock
          }
        ]
      },
      {
        path: "tasasiva",
        children: [
          {
            path: "",
            name: "tasasiva",
            component: TasasIva
          }
        ]
      },
      {
        path: "monedas",
        children: [
          {
            path: "",
            name: "monedas",
            component: Monedas
          }
        ]
      },
      {
        path: "ImportadorXML",
        children: [
          {
            path: "",
            name: "ImportadorXML",
            component: ImportadorXML
          }
        ]
      },
      {
        path: "CFEs",
        children: [
          {
            path: "",
            name: "CFEs",
            component: CFEs
          }
        ]
      },
      {
        path: "CFEsRecibidos",
        children: [
          {
            path: "",
            name: "CFEsRecibidos",
            component: CFEsRecibidos
          }
        ]
      },
      {
        path: "CFEReportesDiarios",
        children: [
          {
            path: "",
            name: "CFEReportesDiarios",
            component: CFEReportesDiarios
          }
        ]
      },

      {
        path: "documentos",
        children: [
          {
            path: "",
            name: "Documentos",
            component: Documentos
          }
        ]
      },
      {
        path: "clientes",
        children: [
          {
            path: "",
            name: "clientes",
            component: Clientes
          },
          {
            path: "cuentasCorrientes",
            children: [
              {
                path: "",
                name: "ClientesCuentasCorrientes",
                component: ClientesCuentasCorrientes
              }
            ]
          },
          {
            path: "recibosCliente",
            children: [
              {
                path: "",
                name: "ClientesRecibos",
                component: ClientesRecibos
              }
            ]
          }
        ]
      },
      {
        path: "proveedores",
        children: [
          {
            path: "",
            name: "proveedores",
            component: Proveedores
          },
          {
            path: "cuentasCorrientes",
            children: [
              {
                path: "",
                name: "ProveedoresCuentasCorrientes",
                component: ProveedoresCuentasCorrientes
              }
            ]
          },
          {
            path: "recibosProveedor",
            children: [
              {
                path: "",
                name: "ProveedoresRecibos",
                component: ProveedoresRecibos
              }
            ]
          }
        ]
      },
      {
        path: "resguardos",
        children: [
          {
            path: "",
            name: "resguardos",
            component: Resguardos
          }
        ]
      },
      {
        path: "resguardosRecibidos",
        children: [
          {
            path: "",
            name: "resguardosRecibidos",
            component: ResguardosRecibidos
          }
        ]
      },
      {
        path: "locales",
        children: [
          {
            path: "",
            name: "locales",
            component: Locales
          }
        ]
      },
      {
        path: "ventas",
        children: [
          {
            path: "",
            name: "ventas",
            component: Ventas
          },
          {
            path: "agregar",
            children: [
              {
                path: "",
                name: "ventasAgregar",
                component: VentasAgregar
              }
            ]
          }
        ]
      },
      {
        path: "compras",
        children: [
          {
            path: "",
            name: "compras",
            component: Compras
          },
          {
            path: "rubros",
            children: [
              {
                path: "",
                name: "comprasRubros",
                component: ComprasRubros
              }
            ]
          },
          {
            path: "agregar",
            children: [
              {
                path: "",
                name: "comprasAgregar",
                component: ComprasAgregar
              }
            ]
          }
        ]
      },
      {
        path: "estadisticas-venta",
        children: [
          {
            path: "",
            name: "estadisticasVenta",
            component: EstadisticasVenta
          }
        ]
      },
      {
        path: "estadisticas-compra",
        children: [
          {
            path: "",
            name: "estadisticasCompra",
            component: EstadisticasCompra
          }
        ]
      },
      {
        path: "estadisticas-centro-costos",
        children: [
          {
            path: "",
            name: "estadisticasCentroCostos",
            component: EstadisticasCentroCostos
          }
        ]
      },
      {
        path: "usuarios",
        children: [
          {
            path: "",
            name: "usuarios",
            component: Usuarios
          }
        ]
      },
      {
        path: "usuarios-pos-handy",
        children: [
          {
            path: "",
            name: "usuariosPosHandy",
            component: UsuariosPosHandy
          }
        ]
      },
      {
        path: "opciones-generales",
        children: [
          {
            path: "",
            name: "opcionesGenerales",
            component: OpcionesGenerales
          }
        ]
      },
      {
        path: "opciones-ventas",
        children: [
          {
            path: "",
            name: "opcionesVentas",
            component: OpcionesVentas
          }
        ]
      },
      {
        path: "opciones-compras",
        children: [
          {
            path: "",
            name: "opcionesCompras",
            component: OpcionesCompras
          }
        ]
      },
      {
        path: "opciones-cotizaciones",
        children: [
          {
            path: "",
            name: "opcionesCotizaciones",
            component: OpcionesCotizaciones
          }
        ]
      },
      {
        path: "unidades",
        children: [
          {
            path: "",
            name: "unidades",
            component: Unidades
          }
        ]
      },
      {
        path: "centro-costos",
        children: [
          {
            path: "",
            name: "centroCostos",
            component: CentroCostos
          }
        ]
      },
      {
        path: "cuenta-ajena",
        children: [
          {
            path: "",
            name: "cuentaAjena",
            component: CuentaAjena
          }
        ]
      },
      {
        path: "usuarios-roles",
        children: [
          {
            path: "",
            name: "usuariosRoles",
            component: UsuariosRoles
          }
        ]
      },
      {
        path: "formas-pago-plazos",
        children: [
          {
            path: "",
            name: "FormasDePagoPlazos",
            component: FormasDePagoPlazos
          }
        ]
      },
      {
        path: "cae",
        children: [
          {
            path: "",
            name: "cae",
            component: CAE
          }
        ]
      },
      {
        path: "constancia-contingencia",
        children: [
          {
            path: "",
            name: "constancia-contingencia",
            component: ConstanciaContigencia
          }
        ]
      },
      {
        path: "firma-electronica",
        children: [
          {
            path: "",
            name: "firma-electronica",
            component: FirmaElectronica
          }
        ]
      },
      {
        path: "envio-mail",
        children: [
          {
            path: "",
            name: "envio-mail",
            component: CFEEnvioMail
          }
        ]
      },
      {
        path: "eboletas-compra",
        children: [
          {
            path: "",
            name: "eboleta",
            component: eBoletaCompra
          }
        ]
      },
      {
        path: "declaracionBeneficio",
        children: [
          {
            path: "",
            name: "declaracionBeneficio",
            component: DeclaracionBeneficio
          }
        ]
      }
    ]
  },
  {
    path: "/login",
    name: "login",
    component: Login
  },
  {
    path: "/loginMagicLink",
    name: "loginMagicLink",
    component: LoginMagicLink
  },
  {
    path: "/recibirTokenOAuthGoogle",
    name: "recibirTokenOAuthGoogle",
    component: RecibirTokenOAuthGoogle
  },
  {
    path: "/recibirTokenOAuthGoogleLectorMails",
    name: "recibirTokenOAuthGoogleLectorMails",
    component: RecibirTokenOAuthGoogleLectorMails
  },
  {
    path: "/logout",
    name: "logout",
    component: Logout
  },
  {
    path: "/registro",
    name: "registro",
    component: Registro
  },
  {
    path: "/analisisDeuda",
    name: "analisisDeuda",
    component: AnalisisDeuda
  },
  {
    path: "/registroHandy",
    name: "registroHandy",
    component: RegistroHandy
  },
  {
    path: "/aceptarInvitacion/:idEmpresaInvita",
    name: "aceptarInvitacion",
    component: AceptarInvitacion,
    props: true
  },
  {
    path: "/soporteFixed/:idEmpresa",
    name: "soporteFixed",
    component: SoporteFixed,
    props: true
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || {left: 0, top: 0};
  }
});

router.beforeEach(async (to, from) => {
  const userStore = userUserStore();
  const isLoggedIn = userStore.userLoggedIn != null;

  if (to.name == "login") {
    document.title = "Fixed | Iniciar sesión";
    return;
  } else if (to.name == "logout") {
    document.title = "Fixed";
    return;
  } else if (to.name == "registro") {
    document.title = "Fixed | Registro";
    return;
  } else if (to.name == "analisisDeuda") {
    document.title = "Detalle de deuda";
    return;
  } else if (to.name == "registroHandy") {
    document.title = "Fixed | Registro";
    return;
  } else if (to.name == "aceptarInvitacion") {
    document.title = "Fixed | Aceptar invitación";
    return;
  } else if (to.name == "recibirTokenOAuthGoogle") {
    document.title = "Fixed";
    return;
  } else if (to.name == "soporteFixed") {
    document.title = "Fixed";
    return;
  } else if (to.name == "loginMagicLink") {
    document.title = "Fixed | Acceso";
    return;
  }

  if (!isLoggedIn || userStore.empresa == null) {
    return {name: "login"};
  } else {
    document.title = `Fixed | ${userStore.empresa?.nombreComercial}`;
    await userStore.checkListenerEmpresa();
  }
});

export default router;
