import { defineStore } from "pinia";
import {
  collection,
  query,
  getFirestore,
  where,
  getDocs,
  addDoc,
  doc,
  setDoc,
  runTransaction,
  orderBy
} from "firebase/firestore";
import { userUserStore } from "../stores/user";
import { useMensajesStore } from "./mensajes";
import {
  Caja,
  CajaMovimiento,
  CajaTipoMovimiento,
  cajaConverter,
  cajaMovimientoConverter
} from "@fixeduy/fixedtypes";

export const useCajasStore = defineStore({
  id: "caja",
  persist: true,
  state: () => {
    return {
      _cajas: [] as Caja[]
    };
  },
  getters: {
    cajas(): Caja[] {
      return this._cajas;
    },
    cajasActivas(): Caja[] {
      return this._cajas.filter(caja => caja.activo);
    },
    getUltimaCajaEdicion(): number {
      let mayorFechaUltimaEdicion = 0;
      this._cajas.map(r => {
        if (r.ultimaEdicion > mayorFechaUltimaEdicion) {
          mayorFechaUltimaEdicion = r.ultimaEdicion;
        }
      });
      return mayorFechaUltimaEdicion;
    }
  },
  actions: {
    async getFromFirestore() {
      const db = getFirestore();
      const userStore = userUserStore();
      const collectionRoute = `empresas/${userStore.empresa?.id}/cajas/`;
      const mayorFechaUltimaEdicion = this.getUltimaCajaEdicion;

      return new Promise<void>((resolve, reject) => {
        getDocs(
          query(
            collection(db, collectionRoute),
            where("ultimaEdicion", ">=", mayorFechaUltimaEdicion)
          ).withConverter(cajaConverter)
        ).then(querySnapshot => {
          querySnapshot.forEach(doc => {
            this.addCajaToStore(doc.data());
          });
          resolve();
        });
      });
    },
    addCajaToStore(caja: Caja) {
      // reviso que no exista
      for (var i = 0; i < this._cajas.length; i++) {
        if (this._cajas[i].id == caja.id) {
          this._cajas[i] = caja;
          return; // corto ejecucion de la funcion
        }
      }

      // lo inserto y ordeno
      this._cajas.push(caja);
      this._cajas.sort((a, b) => (b.nombre > a.nombre ? -1 : 1));
    },
    async crearcaja(caja: Caja) {
      try {
        const mensajesStore = useMensajesStore();
        const db = getFirestore();
        const userStore = userUserStore();
        const collectionRoute = `empresas/${userStore.empresa!.id}/cajas/`;

        const docRef = doc(collection(db, collectionRoute));
        caja.id = docRef.id;
        await setDoc(docRef, caja);
        this.addCajaToStore(caja);

        setDoc(
          doc(db, "empresas", userStore.empresa!.id),
          {
            ultimaEdicionCajas: new Date().getTime()
          },
          { merge: true }
        );

        mensajesStore.mostrarToast(
          "Caja creada con éxito",
          caja.nombre,
          mensajesStore.mensajeType.exito
        );

        return true;
      } catch (error) {
        console.error(error);
      }
    },
    getCajaById(id: string): Caja | null {
      for (var i = 0; i < this._cajas.length; i++) {
        if (this._cajas[i].id == id) {
          return this._cajas[i];
        }
      }
      return null;
    },
    async editarCaja(caja: Caja) {
      const db = getFirestore();
      const userStore = userUserStore();
      const collectionRoute = `empresas/${userStore.empresa!.id}/cajas/`;

      const docRef = doc(db, collectionRoute, caja.id);
      // await setDoc(
      //   docRef,
      //   {
      //     nombre: caja.nombre,
      //     sigla: caja.sigla,
      //     valor: caja.valor,
      //     cfeDgi: caja.cfeDgi,
      //     activoProductos: caja.activoProductos,
      //     activoServicios: caja.activoServicios,
      //     ultimaEdicion: caja.ultimaEdicion
      //   },
      //   { merge: true }
      // );

      await setDoc(docRef, {
        version: caja.version,
        id: caja.id,
        nombre: caja.nombre,
        puedenRetirar: caja.puedenRetirar,
        puedenAceptarDepositos: caja.puedenAceptarDepositos,
        mediosCobro: caja.mediosCobro,
        mediosPago: caja.mediosPago,
        saldoMonedas: caja.saldoMonedas,
        saldoMedios: caja.saldoMedios,
        depositosPendientes: caja.depositosPendientes,
        depositosPendientesImportes: caja.depositosPendientesImportes,
        depositosPendientesAceptar: caja.depositosPendientesAceptar,
        depositosPendientesAceptarImportes:
          caja.depositosPendientesAceptarImportes,
        locales: caja.locales,
        admiteDepositos: caja.admiteDepositos,
        ultimaEdicion: caja.ultimaEdicion,
        activo: caja.activo
      });

      setDoc(
        doc(db, "empresas", userStore.empresa!.id),
        {
          ultimaEdicionCajas: new Date().getTime()
        },
        { merge: true }
      );

      // if (!caja.activo) {
      //   this._cajas = this._cajas.filter(caja => caja.activo);
      // } else {
      for (let i = 0; i < this._cajas.length; i++) {
        if (this._cajas[i].id == caja.id) {
          this._cajas[i] = caja;
          break;
        }
      }
      // }

      return true;
    },
    async ajustarSaldo(
      caja: Caja,
      montoAjuste: number,
      moneda: string,
      medioCobro: string,
      detalle: string,
      tipoAjuste: CajaTipoMovimiento
    ) {
      const db = getFirestore();
      const userStore = userUserStore();
      const idEmpresa = userStore.empresa?.id;

      if (idEmpresa == null) {
        return;
      }

      if (tipoAjuste == CajaTipoMovimiento.AjusteQuitar) {
        montoAjuste = montoAjuste * -1;
      }
      await runTransaction(db, async transaction => {
        const saldoPrevioDocRef = await transaction.get(
          doc(db, `empresas/${idEmpresa}/cajas/`, caja.id).withConverter(
            cajaConverter
          )
        );

        if (!saldoPrevioDocRef.exists()) {
          // Seria que no existe la caja
          return;
        }
        const saldoPrevio = saldoPrevioDocRef.data();

        if (!(medioCobro in saldoPrevio.saldoMedios)) {
          saldoPrevio.saldoMedios[medioCobro] = {};
        }
        if (!(moneda in saldoPrevio.saldoMedios[medioCobro])) {
          saldoPrevio.saldoMedios[medioCobro][moneda] = 0;
        }

        if (!(moneda in saldoPrevio.saldoMonedas)) {
          saldoPrevio.saldoMonedas[moneda] = 0;
        }

        let saldoMonedas = saldoPrevio.saldoMonedas;
        let saldoMedios = saldoPrevio.saldoMedios;

        // Ahora sumo/resto
        saldoMonedas[moneda] = saldoMonedas[moneda] + montoAjuste;
        saldoMedios[medioCobro][moneda] =
          saldoMedios[medioCobro][moneda] + montoAjuste;

        // SaldoMonedas y saldoMedios tienen los nuevos saldos
        const cajaMovRef = collection(
          db,
          `empresas/${idEmpresa}/cajas/${caja.id}/movimientos`
        );
        const newMovRef = doc(cajaMovRef).withConverter(
          cajaMovimientoConverter
        );

        transaction.set(newMovRef, {
          saldoMedios: saldoMedios,
          version: 1,
          id: "",
          autor: userStore.userLoggedIn!.uid,
          fechaCreacion: new Date().getTime(),
          saldoMonedas: saldoMonedas,
          detalle: detalle,
          tipo: tipoAjuste,
          idDocumento: "",
          moneda: moneda,
          medioCobro: medioCobro,
          monto: montoAjuste,
          camposExtra: {}
        });

        transaction.update(doc(db, `empresas/${idEmpresa}/cajas/${caja.id}`), {
          ultimaEdicion: new Date().getTime(),
          saldoMedios: saldoMedios,
          saldoMonedas: saldoMonedas
        });
      });

      setDoc(
        doc(db, "empresas", userStore.empresa!.id),
        {
          ultimaEdicionCajas: new Date().getTime()
        },
        { merge: true }
      );
    },

    async getMovimientosDeCuenta(
      caja: Caja,
      fechaInicio: number,
      fechaFin: number
    ) {
      const db = getFirestore();
      const userStore = userUserStore();
      const idEmpresa = userStore.empresa?.id;

      const movimientos: CajaMovimiento[] = [];

      const querySnapshot = await getDocs(
        query(
          collection(db, `empresas/${idEmpresa}/cajas/${caja.id}/movimientos`),
          where("fechaCreacion", ">=", fechaInicio),
          where("fechaCreacion", "<=", fechaFin),
          orderBy("fechaCreacion", "asc")
        ).withConverter(cajaMovimientoConverter)
      );

      querySnapshot.forEach(doc => movimientos.push(doc.data()));

      return movimientos;
    }
  }
});
