<script setup lang="ts">
import {onBeforeMount, ref} from "vue";
import Lucide from "../../base-components/Lucide";
import Breadcrumb from "../../base-components/Breadcrumb";
import {Menu, Popover} from "../../base-components/Headless";
import _ from "lodash";
import {userUserStore} from "../../stores/user";
import {getAuth, signOut} from "firebase/auth";
import Button from "../../base-components/Button";
import {useLocalesStore} from "../../services/locales";
import {IHandyPOSConfig, Local, Notificacion, Usuario, utilFechaConvertirNumeroString} from "@fixeduy/fixedtypes";
import ModalElegirLocales from "./components/modals/ModalElegirLocales.vue"; // Adjust the path accordingly
import DarkModeSwitcher from "../DarkModeSwitcher";
import {doc, getFirestore, setDoc} from "firebase/firestore";
import Alert from "../../base-components/Alert/Alert.vue";
import {useUsuariosStore} from "../../services/usuarios";

const ambiente = import.meta.env.VITE_ENV_NOMBRE;
const props = defineProps<{
  layout?: "side-menu" | "simple-menu" | "top-menu";
}>();

const userStore = userUserStore();
const usuariosStore = useUsuariosStore();
const autorUserId = userStore.userLoggedIn?.uid ?? "";
const db = getFirestore();
const browserName = ref("Unknown");
const usuarioLogueado = ref<Usuario>();
const localesStore = useLocalesStore();

let localesDisponibles: Local[];

if (userStore.usuario?.locales.length == undefined || userStore.usuario?.locales.length > 0) {
  localesDisponibles = localesStore.localesActivos.filter(l => userStore.usuario?.locales.includes(l.id));
} else {
  localesDisponibles = localesStore.localesActivos;
}

onBeforeMount(async () => {
  browserName.value = detectBrowser();
  usuarioLogueado.value = await usuariosStore.getById(autorUserId)!;
  if (localesDisponibles.length === 1) {
    cambiarLocal(localesDisponibles[0]);
  }
});

const agregarNotificacion = (notificacion: Notificacion) => {
  userStore.arrayNotificaciones = userStore.arrayNotificaciones.filter(
    data => data.id != notificacion.id && data.visto == false && data.empresa == userStore.empresa?.id
  );
  userStore.arrayNotificaciones.push(notificacion);
  userStore.arrayNotificaciones = userStore.arrayNotificaciones.sort((a, b) => b.fecha - a.fecha);
};

const marcarVisto = async (id: string) => {
  await setDoc(
    doc(db, `usuarios/${autorUserId}/notificaciones/${id}`),
    {visto: true},
    {
      merge: true
    }
  );

  userStore.arrayNotificaciones = userStore.arrayNotificaciones.filter(
    data => data.id != id && data.visto == false && data.empresa == userStore.empresa?.id
  );
  userStore.arrayNotificaciones = userStore.arrayNotificaciones.sort((a, b) => b.fecha - a.fecha);
};

const searchDropdown = ref(false);
const showSearchDropdown = () => {
  searchDropdown.value = true;
};
const hideSearchDropdown = () => {
  searchDropdown.value = false;
};

const staticBackdropModalPreview = ref(false);

const setStaticBackdropModalPreview = (show: boolean) => {
  staticBackdropModalPreview.value = show;
};

const showLocalesModal = ref(false);

const logoutHandler = () => {
  userStore.signOut();
  const auth = getAuth();
  signOut(auth);
};

const cambiarLocal = (local: Local) => {
  userStore.setLocalActual(local);
};
const cambiarPOS = (pos: IHandyPOSConfig) => {
  userStore.setPOSActual(pos);
};

const cerrarModal = () => {
  showLocalesModal.value = false;
};

const isMobile = () => {
  const userAgent = navigator.userAgent.toLowerCase();

  // Check if the user is on a mobile device
  return /mobile|android|iphone|ipad|ipod|blackberry|iemobile|opera mini/.test(userAgent);
};

const detectBrowser = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  let browserName = "Unknown";

  if (userAgent.includes("edg/")) {
    browserName = "Edge";
  } else if (userAgent.includes("firefox/") || userAgent.includes("fxios")) {
    browserName = "Firefox";
  } else if (userAgent.includes("opr/") || userAgent.includes("opera")) {
    browserName = "Opera";
  } else if (userAgent.includes("chrome/") && !userAgent.includes("edg/") && !userAgent.includes("opr/")) {
    browserName = "Chrome";
  } else if (userAgent.includes("safari/") && !userAgent.includes("chrome/")) {
    browserName = "Safari";
  } else if (userAgent.includes("trident/") || userAgent.includes("msie")) {
    browserName = "Internet Explorer";
  }

  return browserName;
};
</script>

<template>
  <div
    style="z-index: 100"
    :class="[
      'h-[70px] md:h-[65px] z-[51] border-b border-white/[0.08] mt-12 md:mt-0 -mx-3 sm:-mx-8 md:-mx-0 px-3 md:border-b-0 relative md:fixed md:inset-x-0 md:top-0 sm:px-8 md:px-10 md:pt-10 md:bg-gradient-to-b md:from-slate-100 md:to-transparent dark:md:from-darkmode-700',
      props.layout == 'top-menu' && 'dark:md:from-darkmode-800',
      'before:content-[\'\'] before:absolute before:h-[65px] before:inset-0 before:top-0 before:mx-7 before:bg-primary/30 before:mt-3 before:rounded-xl before:hidden before:md:block before:dark:bg-darkmode-600/30',
      'after:content-[\'\'] after:absolute after:inset-0 after:h-[65px] after:mx-3 after:bg-primary after:mt-5 after:rounded-xl after:shadow-md after:hidden after:md:block after:dark:bg-darkmode-600 '
    ]"
  >
    <div class="flex items-center h-full">
      <!-- BEGIN: Logo -->
      <div
        :class="[
          '-intro-x hidden md:flex',
          props.layout == 'side-menu' && 'xl:w-[125px]',
          props.layout == 'simple-menu' && 'xl:w-auto',
          props.layout == 'top-menu' && 'w-auto'
        ]"
      >
        <template v-if="ambiente == 'Produccion'">
          <img alt="Fixed" class="w-28" src="/logo_fondo_gris.png" />
        </template>
        <template v-else
          ><span
            :class="[
              'text-lg text-white',
              props.layout == 'side-menu' && 'hidden xl:block',
              props.layout == 'simple-menu' && 'hidden'
            ]"
          >
            {{ ambiente }}
          </span>
        </template>
      </div>
      <!-- END: Logo -->
      <!-- BEGIN: Breadcrumb -->

      <Breadcrumb
        light
        :class="[
          'h-[45px] md:ml-10 md:border-l border-white/[0.08] dark:border-white/[0.08] mr-auto -intro-x text-2xl',
          props.layout != 'top-menu' && 'md:pl-6',
          props.layout == 'top-menu' && 'md:pl-10'
        ]"
      >
        {{ userStore.empresa?.nombreComercial }} - {{ userStore.empresa?.rut }}
        <div v-if="!isMobile() && browserName != 'Chrome'">
          <h1 class="m-2 text-danger">Recomendamos usar Google Chrome.</h1>
        </div>
      </Breadcrumb>
      <!-- END: Breadcrumb -->

      <div class="relative mr-3 intro-x sm:mr-6">
        <DarkModeSwitcher />
      </div>

      <!-- BEGIN: POS -->
      <div class="relative mr-3 intro-x sm:mr-6">
        <Menu>
          <Menu.Button
            :as="Button"
            variant="primary"
            v-if="
              userStore.opcionesGenerales.integracionPOSHandy.habilitado &&
              userStore.opcionesGenerales.integracionPOSHandy.pos.length > 1
            "
          >
            Pos: {{ userStore.handyPOSactual?.nombre }}
          </Menu.Button>
          <Menu.Items class="w-40">
            <template v-for="pos in userStore.opcionesGenerales.integracionPOSHandy.pos" :key="pos.id">
              <Menu.Item @click="cambiarPOS(pos)"> {{ pos.nombre }} <br /> </Menu.Item>
            </template>
          </Menu.Items>
        </Menu>
      </div>
      <!-- END: Locales -->

      <!-- BEGIN: Locales -->
      <div class="relative mr-3 intro-x sm:mr-6">
        <Menu>
          <Menu.Button :as="Button" variant="primary" v-if="localesDisponibles.length > 1">
            Local: {{ userStore.localActual?.nombre }}
          </Menu.Button>
          <Menu.Items class="w-40">
            <template v-for="local in localesDisponibles" :key="local.id">
              <template v-if="local.activo">
                <Menu.Item @click="cambiarLocal(local)"> {{ local.nombre }} <br /> </Menu.Item>
              </template>
            </template>
          </Menu.Items>
        </Menu>
      </div>
      <!-- END: Locales -->

      <!-- BEGIN: Empresas -->
      <div v-if="!isMobile() && userStore.empresasRegistradas.length > 1" class="relative mr-3 intro-x sm:mr-3">
        <router-link :to="{name: 'login'}">
          <Button variant="primary"> Cambiar de empresa </Button>
        </router-link>
      </div>
      <!-- END: Empresas -->

      <!-- BEGIN: Notifications -->
      <Popover class="mr-4 intro-x sm:mr-6">
        <Popover.Button
          v-bind:class="[
            'relative text-white/70 outline-none block before:content-[\'\'] before:w-[8px] before:h-[8px] before:rounded-full before:absolute before:top-[-2px] before:right-0',
            {'before:bg-danger': userStore.arrayNotificaciones.length > 0}
          ]"
        >
          <Lucide icon="Bell" class="w-5 h-5 dark:text-slate-500" />
        </Popover.Button>
        <Popover.Panel class="w-[280px] sm:w-[350px] p-5 mt-2">
          <div class="text-base font-medium">Notificaciones</div>
          <div
            v-if="userStore.arrayNotificaciones.length > 0"
            class="overflow-y-auto scrollbar-thin scrollbar-thumb-primary scrollbar-track-gray-100 dark:scrollbar-track-gray-800 dark:scrollbar-thumb-primary-dark"
            style="max-height: 350px"
          >
            <div
              v-for="(notificacion, index) in userStore.arrayNotificaciones"
              :key="notificacion.id"
              class="notificacion-container cursor-pointer items-center"
            >
              <Alert variant="secondary" class="mt-2">
                <div class="notificacion-content flex rounded-lg">
                  <div class="flex">
                    <div class="text-danger mt-2 me-2">!</div>
                    <div>
                      {{ utilFechaConvertirNumeroString(notificacion.fecha, false, "/") }}
                      -
                      {{ notificacion.texto }}
                    </div>
                    <div>
                      <Lucide icon="XCircleIcon" class="mt-2 text-danger" @click="marcarVisto(notificacion.id)" />
                    </div>
                  </div>
                </div>
              </Alert>
            </div>
          </div>
          <div v-else class="m-5 text-warning">No hay notificaciones pendientes</div>
        </Popover.Panel>
      </Popover>
      <!-- END: Notifications -->
      <!-- BEGIN: Account Menu -->
      <Menu>
        <Menu.Button
          ><div
            v-if="userStore.user?.photoURL!"
            class="block w-8 h-8 overflow-hidden rounded-full shadow-lg image-fit zoom-in intro-x"
          >
            <img alt="Perfil" :src="userStore.user?.photoURL!" />
          </div>
          <div class="block overflow-hidden rounded-full shadow-lg image-fit zoom-in intro-x text-white/70" v-else>
            <Lucide class="w-full h-full dark:text-slate-500" icon="CircleUserRound"></Lucide>
          </div>
        </Menu.Button>
        <Menu.Items
          class="w-64 mt-px relative bg-primary/80 before:block before:absolute before:bg-black before:inset-0 before:rounded-md before:z-[-1] text-white"
        >
          <Menu.Header class="font-normal">
            <div class="font-medium">{{ userStore.user?.displayName }}</div>
          </Menu.Header>
          <template v-if="isMobile() && userStore.empresasRegistradas.length > 1">
            <Menu.Divider class="bg-white/[0.08]" />
            <Menu.Header class="font-normal">
              <router-link :to="{name: 'login'}">
                <div class="font-medium">Cambiar de empresa</div>
              </router-link>
            </Menu.Header>
          </template>
          <Menu.Divider class="bg-white/[0.08]" />
          <Menu.Header class="font-normal">
            <div class="font-medium">Código promocional: {{ userStore.codigoPromocional }}</div>
          </Menu.Header>
          <Menu.Divider class="bg-white/[0.08]" />
          <div class="font-medium ml-2">{{ userStore.user?.email }}</div>
          <Menu.Divider class="bg-white/[0.08]" />
          <router-link :to="{name: 'perfil'}">
            <Menu.Item class="hover:bg-white/5"><Lucide icon="User" class="w-4 h-4 mr-2" />Perfil </Menu.Item>
          </router-link>
          <Menu.Item class="hover:bg-white/5"> <Lucide icon="Edit" class="w-4 h-4 mr-2" /> Mi cuenta </Menu.Item>
          <Menu.Divider class="bg-white/[0.08]" />
          <Menu.Item class="hover:bg-white/5" @click="logoutHandler">
            <Lucide icon="ToggleRight" class="w-4 h-4 mr-2" /> Cerrar sesión
          </Menu.Item>
        </Menu.Items>
      </Menu>
      <!-- END: Account Menu -->
    </div>
  </div>
  <ModalElegirLocales
    v-if="userStore.localActual == null && usuarioLogueado?.locales.length != 1"
    :locales="localesDisponibles"
    @cambiarLocal="cambiarLocal"
    @cerrar="cerrarModal"
  />
</template>
