import { createApp } from "vue";
import { createPinia } from "pinia";
import piniaPluginPersistedState from "pinia-plugin-persistedstate";
import App from "./App.vue";
import router from "./router";
import "./assets/css/app.css";
import { initializeApp } from "firebase/app";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check";
import * as Sentry from "@sentry/vue";
import { userUserStore } from "./stores/user";

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID
  // measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
if (import.meta.env.VITE_ENV_NOMBRE == "Produccion") {
  const appCheck = initializeAppCheck(firebaseApp, {
    provider: new ReCaptchaEnterpriseProvider("6Lexb7cpAAAAAEKORmVeIJjjoW9pO0yENO38kJIm"),
    isTokenAutoRefreshEnabled: true // Set to true to allow auto-refresh.
  });
}

getAuth().onAuthStateChanged(change => {
  if (change == null) {
    const userStore = userUserStore();
    const routeName = router.currentRoute.value.name;

    if (
      routeName != undefined &&
      routeName != "login" &&
      routeName != "logout" &&
      routeName != "registro" &&
      routeName != "analisisDeuda" &&
      routeName != "registroHandy" &&
      routeName != "loginMagicLink" &&
      routeName != "aceptarInvitacion"
    ) {
      userStore.signOut();
    }
  }
});

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(firebaseApp);
if (import.meta.env.VITE_USAR_EMULADOR == "true") {
  console.warn("*** Conectado al emulador de Firestore ***");
  connectFirestoreEmulator(db, "127.0.0.1", 8080);
}

const app = createApp(App);

if (import.meta.env.VITE_ENV_NOMBRE == "Produccion") {
  Sentry.init({
    app,
    dsn: "https://b67c3151f00e409a4cdacb56bbcb6fde@o4507075078324224.ingest.us.sentry.io/4507075079897088",
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration({ maskAllText: false })],
    environment: import.meta.env.VITE_ENV_NOMBRE,
    // Performance Monitoring
    tracesSampleRate: 0.1, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [],
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 0.1 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const pinia = createPinia();
pinia.use(piniaPluginPersistedState);

app.use(router).use(pinia).mount("#app");
