import { defineStore } from "pinia";
import { User, IdTokenResult } from "firebase/auth";
import {
  Unsubscribe,
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  onSnapshot,
  query,
  setDoc,
  updateDoc,
  where
} from "firebase/firestore";
import { useComprasRubrosStore } from "../services/compras/comprasRubros";
import { useServiciosRubrosStore } from "../services/servicios/serviciosRubros";
import { useProductosRubrosStore } from "../services/productos/productosRubros";
import { useTasasIVAStore } from "../services/tasas";
import { useServiciosListasPreciosStore } from "../services/servicios/serviciosListasPrecios";
import { useProductosListasPreciosStore } from "../services/productos/productosListasPrecios";
import { useMonedasStore } from "../services/monedas";
import { useCajasMediosPagoStore } from "../services/cajasMediosPago";
import { useCajasMediosCobroStore } from "../services/cajasMediosCobro";
import { useLocalesStore } from "../services/locales";
import { useUnidadesStore } from "../services/unidades";
import { useCentroCostosStore } from "../services/centroCostos";
import { useCuentaAjenaStore } from "../services/cuentasAjenas";
import { useMensajesStore } from "../services/mensajes";
import { useUsuariosStore } from "../services/usuarios";
import * as Sentry from "@sentry/vue";

import { usePagoEnPlazosStore } from "../services/formasDePagosPlazos";
import {
  Local,
  OpcionesGenerales,
  OpcionesGeneralesServicios,
  OpcionesGeneralesProductos,
  OpcionesGeneralesCamposAdicionales,
  OpcionesGeneralesRegimenExonerado,
  OpcionesGeneralesFacturacionElectronica,
  OpcionesCompras,
  opcionesGeneralesDefecto,
  opcionesCompraDefecto,
  OpcionesGeneralesImpresion,
  opcionesGeneralesImpresionDefecto,
  opcionesGeneralesFacturacionElectronicaDefecto,
  UsuarioEmpresas,
  defectoOpcionesGeneralesServicios,
  defectoOpcionesGeneralesProductos,
  defectoCamposAdicionales,
  defectoOpcionesGeneralesRegimenExonerado,
  opcionesVentaRolDefecto,
  MecanismosEnvioMails,
  usuarioConverter,
  Usuario,
  ChatMensaje,
  DeclaracionBeneficio,
  Notificacion,
  UsuarioSistemaInvitacion,
  usuarioSistemaInvitacionConverter,
  chatMensajeConverter,
  IHandyPOSConfig,
  IHandyConfig,
  IFiservConfig,
  IFiservPOSConfig,
  utilFechaGetCurrentTime,
  DocumentosPendientesExcel
} from "@fixeduy/fixedtypes";
import { OpcionesVentaRol } from "@fixeduy/fixedtypes/dist/types/opcionesVentaRol";
import { useCajasStore } from "../services/cajas";
import { useCotizacionesStore } from "../services/cotizaciones";
import { useCAEStore } from "../services/caes";
import { useCotizacionesBCUStore } from "../services/cotizacionesBCU";
import { useCuentaIVAStore } from "../services/cuentaIVA";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import router from "../router";
import { useServiciosCacheStore } from "../services/servicios/serviciosCacheStore";
import { useProductosCacheStore } from "../services/productos/productosCacheStore";
import { useVentasBorradoresStore } from "../services/ventasBorradores";
import { facturasPendientesFixed } from "../services/cloudFunctions/facturasPendientesFixed";

export const userUserStore = defineStore({
  id: "userStore",
  persist: true,
  state: () => {
    return {
      userLoggedIn: null as User | null,
      token: null as IdTokenResult | null,
      usuario: null as Usuario | null,
      empresa: null as UsuarioEmpresas | null,
      empresasRegistradas: [] as UsuarioEmpresas[],
      vencimientoFirma: 0 as number,
      chatAbierto: false as boolean,
      alineacionChat: "derecha" as "izquierda" | "derecha",
      tamanoChat: "chico" as "chico" | "grande",
      chatMensajes: [] as ChatMensaje[],
      suscriberChatMensajes: null as Unsubscribe | null,
      suscriberSnapshotUsuario: null as Unsubscribe | null,
      suscriberSnapshotEmpresa: null as Unsubscribe | null,
      suscriberSnapshotNotificaciones: null as Unsubscribe | null,
      arrayNotificaciones: [] as Notificacion[],
      ultimaVersionFixed: 0,
      cacheCuentaIVA: 0 as number,
      cacheCAE: 0 as number,
      cacheComprasRubros: 0 as number,
      cacheServiciosRubros: 0 as number,
      cacheProductosRurbos: 0 as number,
      cacheTasasIVA: 0 as number,
      cacheServiciosListasPrecios: 0 as number,
      cacheProductosListasPrecios: 0 as number,
      cacheMonedas: 0 as number,
      cacheMediosPago: 0 as number,
      cacheMediosCobro: 0 as number,
      cacheLocales: 0 as number,
      cacheUnidades: 0 as number,
      cacheCentroCostos: 0 as number,
      cacheCuentaAjena: 0 as number,
      cachePagoEnPlazos: 0 as number,
      cacheUsuarios: 0 as number,
      cacheCajas: 0 as number,
      localActual: null as Local | null,
      opcionesGenerales: opcionesGeneralesDefecto,
      opcionesGeneralesServicios: defectoOpcionesGeneralesServicios,
      opcionesGeneralesProductos: defectoOpcionesGeneralesProductos,
      opcionesGeneralesCamposAdicionales: defectoCamposAdicionales,
      opcionesGeneralesImpresion: opcionesGeneralesImpresionDefecto,
      opcionesGeneralesRegimenExonerado: defectoOpcionesGeneralesRegimenExonerado,
      opcionesGeneralesFacturacionElectronica: opcionesGeneralesFacturacionElectronicaDefecto,
      opcionesCompras: opcionesCompraDefecto,
      mecanismosEnvioMails: [] as MecanismosEnvioMails[],
      opcionesVenta: {} as { [key: string]: OpcionesVentaRol },
      declaracionBeneficio: {} as { [key: string]: DeclaracionBeneficio },
      menuCollapsed: false as boolean,
      googleApi: false as boolean,
      googleApiEmpresasHabilitadas: [] as string[],
      handyPOSactual: null as IHandyPOSConfig | null,
      fiservPOSactual: null as IFiservPOSConfig | null,
      eventScrollToTop: new Date(),
      codigoPromocional: "",
      logoPrincipalURL: "",
      logoPrincipalReferencia: "",
      logoSucursalURL: "",
      logoSucursalReferencia: "",
      inicioSesionMecanismo: "" as "google" | "microsoft" | "magicLink" | "password" | "",
      pendientePagoSaldo: 0 as number,
      pendientePagoFacturas: [] as DocumentosPendientesExcel[],
      pendientePagoDiasFacturaMasAntigua: 0 as number,
    };
  },
  getters: {
    user(): User | null {
      return this.userLoggedIn;
    },
    opcionesVentaUsuario(): OpcionesVentaRol {
      if (
        this.token != null &&
        this.opcionesVenta != null &&
        "rol" in this.token.claims &&
        this.token.claims["rol"] in this.opcionesVenta
      ) {
        return this.opcionesVenta[this.token.claims["rol"]];
      } else {
        return opcionesVentaRolDefecto;
      }
    },
    rolUsuario(): string {
      if (
        this.token != null &&
        this.opcionesVenta != null &&
        "rol" in this.token.claims &&
        this.token.claims["rol"] in this.opcionesVenta
      ) {
        return this.token.claims["rol"];
      } else {
        return "";
      }
    }
  },
  actions: {
    setUser(user: User) {
      this.userLoggedIn = null;
      this.token = null;
      this.empresa = null;
      this.userLoggedIn = user;

      this.vaciarDatos();
    },
    setMecanismoInicioSesion(mecanismo: "google" | "microsoft" | "magicLink" | "password") {
      this.inicioSesionMecanismo = mecanismo;
    },
    scrollToTopMainContent() {
      this.eventScrollToTop = new Date();
    },
    abrirChat() {
      this.chatAbierto = true;
    },
    cerrarChat() {
      this.chatAbierto = false;
    },
    toggleAlineacion() {
      if (this.alineacionChat == "izquierda") {
        this.alineacionChat = "derecha";
      } else {
        this.alineacionChat = "izquierda";
      }
    },
    toggleTamano() {
      if (this.tamanoChat == "chico") {
        this.tamanoChat = "grande";
      } else {
        this.tamanoChat = "chico";
      }
    },
    descartarAlertaUsuario(alerta: string) {
      if (this.usuario != null) {
        this.usuario.alertas[alerta] = true;
        const db = getFirestore();
        const usuarioRef = doc(db, "usuarios", this.user!.uid);
        updateDoc(usuarioRef, {
          alertas: this.usuario.alertas
        });
      }
    },
    async cargarFacturasPendientesPago() {
      const pendientes = await facturasPendientesFixed();
      this.pendientePagoSaldo = pendientes.saldoPesos;
      this.pendientePagoFacturas = pendientes.pendientes;
      let diasFacturaMasAntigua = 0;
      for (const factura of pendientes.pendientes) {
        if (factura.diasAntiguedad > diasFacturaMasAntigua) {
          diasFacturaMasAntigua = factura.diasAntiguedad;
        }
      }
      this.pendientePagoDiasFacturaMasAntigua = diasFacturaMasAntigua;
    },
    async cargarInvitaciones(): Promise<UsuarioSistemaInvitacion[]> {
      const invitacionesEmpresas: UsuarioSistemaInvitacion[] = [];
      if (this.user != null && this.user.email != null) {
        const db = getFirestore();
        const querySnapshot = await getDocs(
          query(collection(db, "invitaciones-usuarios"), where("mail", "==", this.user.email)).withConverter(
            usuarioSistemaInvitacionConverter
          )
        );
        querySnapshot.forEach(doc => {
          invitacionesEmpresas.push(doc.data());
        });
      }
      return invitacionesEmpresas;
    },
    setUserToken(token: IdTokenResult) {
      this.token = token;
    },
    async setOpciones(googleApi: boolean, googleApiEmpresasHabilitadas: string[]) {
      this.googleApi = googleApi;
      this.googleApiEmpresasHabilitadas = googleApiEmpresasHabilitadas;
    },
    async setEmpresasActual(empresa: UsuarioEmpresas | null) {
      Sentry.setContext("Empresa", {
        id: empresa?.id ?? "",
        rut: empresa?.rut ?? "",
        razon_social: empresa?.razonSocial ?? ""
      });
      this.vaciarDatos();
      this.empresa = empresa;

      if (import.meta.env.VITE_ENV_NOMBRE == "Testing" || import.meta.env.VITE_ENV_NOMBRE == "Produccion") {
        Sentry.setTag("rut", empresa?.rut);
      }

      this.usuario = null;
      const usuarioDocRef = doc(
        getFirestore(),
        `empresas/${this.empresa!.id}/usuarios`,
        this.userLoggedIn!.uid
      ).withConverter(usuarioConverter);
      const usuarioDoc = await getDoc(usuarioDocRef);
      const usuarioData = usuarioDoc.data();
      if (usuarioData != null) {
        this.usuario = usuarioData;
      }
      await this.checkListeners();
    },
    async checkListeners() {
      await this.checkListenerEmpresa();
      this.checkListenerUsuario();
      this.checkListenNotificaciones();
      const ventasBorradoresStore = useVentasBorradoresStore();
      ventasBorradoresStore.init();
    },
    async listenerChatMensajes() {
      if (this.empresa != null && this.user != null && this.suscriberChatMensajes == null) {
        // Reviso si hay mensajes:
        const existenMensajes = await getDocs(query(collection(getFirestore(), `chats/${this.empresa.id}-${this.user.uid}/mensajes`), limit(1)));
        if (existenMensajes.docs.length == 0) {
          const mensaje: ChatMensaje = {
            id: "",
            enviadoPorFixed: true,
            autorNombre: this.user.displayName ?? "",
            autorUserId: this.user.uid,
            fecha: utilFechaGetCurrentTime(),
            mensaje: "Hola! este es el chat de soporte, favor de indicarnos con que podemos ayudarte.",
            vistoCliente: true,
            vistoFixed: {},
            notaPrivada: false,
            empresaId: this.empresa.id,
            urlImagenRemitente: "",
            refAdjunto: "",
            urlAdjunto: "",
            nombreAdjunto: "",
            tamanioAdjunto: "",
            resuelto: null,
            reabierto: null,
            clienteId: this.user.uid
          };

          const docRef = doc(collection(getFirestore(), `chats/${this.empresa.id}-${this.user.uid}/mensajes`)).withConverter(
            chatMensajeConverter
          );
          mensaje.id = docRef.id;
          await setDoc(docRef, mensaje);
        }

        this.suscriberChatMensajes = onSnapshot(
          collection(getFirestore(), `chats/${this.empresa.id}-${this.user.uid}/mensajes`).withConverter(
            chatMensajeConverter
          ),
          docs => {
            docs.docs.forEach(doc => {
              const mensaje = doc.data();
              if (mensaje.notaPrivada == false) {
                this.chatMensajes = this.chatMensajes.filter(
                  chat => chat.id != mensaje.id && chat.notaPrivada == false
                );
                this.chatMensajes.push(mensaje);
                this.chatMensajes = this.chatMensajes.sort((a, b) => a.fecha - b.fecha);
              }
            });
          }
        );
      }
    },
    async checkListenerUsuario() {
      if (this.suscriberSnapshotUsuario == null && this.userLoggedIn != null) {
        try {
          this.suscriberSnapshotUsuario = onSnapshot(
            doc(getFirestore(), "usuarios", this.userLoggedIn.uid).withConverter(usuarioConverter),
            docResult => {
              const userData = docResult.data();
              if (userData == null) return;

              const empresaActual = this.empresasRegistradas.find(e => e.id == this.empresa?.id);
              if (empresaActual != null) {
                this.empresa = empresaActual;
              }

              this.setEmpresasRegistradas(userData.empresas);
              this.setOpciones(userData.googleApi, userData.googleApiEmpresasHabilitadas);
            }
          );
        } catch (error) {
          console.log(error);
        }
      }
    },
    async checkListenerEmpresa() {
      return new Promise<void>((resolve, reject) => {
        if (this.suscriberSnapshotEmpresa == null) {
          const comprasRubroStore = useComprasRubrosStore();
          comprasRubroStore.$hydrate();
          const caeStore = useCAEStore();
          caeStore.$hydrate();
          const cuentaIVAStore = useCuentaIVAStore();
          cuentaIVAStore.$hydrate();
          const serviciosRubroStore = useServiciosRubrosStore();
          serviciosRubroStore.$hydrate();
          const productosRubroStore = useProductosRubrosStore();
          productosRubroStore.$hydrate();
          const tasasIVAStore = useTasasIVAStore();
          tasasIVAStore.$hydrate();
          const serviciosListasPreciosStore = useServiciosListasPreciosStore();
          serviciosListasPreciosStore.$hydrate();
          const productosListasPreciosStore = useProductosListasPreciosStore();
          productosListasPreciosStore.$hydrate();
          const monedasStore = useMonedasStore();
          monedasStore.$hydrate();
          const cajasMediosPagoStore = useCajasMediosPagoStore();
          cajasMediosPagoStore.$hydrate();
          const cajasMediosCobroStore = useCajasMediosCobroStore();
          cajasMediosCobroStore.$hydrate();
          const localStore = useLocalesStore();
          localStore.$hydrate();
          const unidadStore = useUnidadesStore();
          unidadStore.$hydrate();
          const centroCostosStore = useCentroCostosStore();
          centroCostosStore.$hydrate();
          const cuentaAjenaStore = useCuentaAjenaStore();
          cuentaAjenaStore.$hydrate();
          const pagoEnPlazosStore = usePagoEnPlazosStore();
          pagoEnPlazosStore.$hydrate();
          const cotizacionesStore = useCotizacionesStore();
          cotizacionesStore.$hydrate();
          const cotizacionesBCUStore = useCotizacionesBCUStore();
          cotizacionesBCUStore.$hydrate();
          const usuariosStore = useUsuariosStore();
          usuariosStore.$hydrate();
          const cajasStore = useCajasStore();
          cajasStore.$hydrate();

          if (this.empresa != null) {
            const promises: Promise<any>[] = [];

            try {
              this.suscriberSnapshotEmpresa = onSnapshot(
                doc(getFirestore(), "empresas", this.empresa!.id),
                async docResult => {
                  const empresa = docResult.data();
                  if (empresa == null) return;
                  this.declaracionBeneficio = empresa.declaracionBeneficio;
                  this.vencimientoFirma = empresa.vencimientoFirma;
                  this.ultimaVersionFixed = empresa.versionFixed ?? 0;

                  if (this.cacheComprasRubros < empresa.ultimaEdicionComprasRubros) {
                    this.cacheComprasRubros = new Date().getTime();
                    promises.push(comprasRubroStore.getRubrosFromFirestore());
                  }
                  if (this.cacheCuentaIVA < empresa.ultimaEdicionCuentaIVA) {
                    this.cacheCuentaIVA = new Date().getTime();
                    promises.push(cuentaIVAStore.getCuentaIVAFromFirestore());
                  }
                  if (this.cacheCAE < empresa.ultimaEdicionCAE) {
                    this.cacheCAE = new Date().getTime();
                    promises.push(caeStore.getCAEsFromFirestore());
                  }

                  if (this.cacheServiciosRubros < empresa.ultimaEdicionServiciosRubros) {
                    this.cacheServiciosRubros = new Date().getTime();
                    promises.push(serviciosRubroStore.getRubrosFromFirestore());
                  }
                  if (this.cacheProductosRurbos < empresa.ultimaEdicionProductosRubros) {
                    this.cacheProductosRurbos = new Date().getTime();
                    promises.push(productosRubroStore.getRubrosFromFirestore());
                  }
                  if (this.cacheUnidades < empresa.ultimaEdicionUnidades) {
                    this.cacheUnidades = new Date().getTime();
                    promises.push(unidadStore.getUnidadesFromFirestore());
                  }
                  if (this.cacheTasasIVA < empresa.ultimaEdicionTasasIVA) {
                    this.cacheTasasIVA = new Date().getTime();
                    promises.push(tasasIVAStore.getTasasFromFirestore());
                  }
                  if (this.cacheServiciosListasPrecios < empresa.ultimaEdicionServiciosListasPrecios) {
                    this.cacheServiciosListasPrecios = new Date().getTime();
                    promises.push(serviciosListasPreciosStore.getServiciosListasPreciosFromFirestore());
                  }
                  if (this.cacheProductosListasPrecios < empresa.ultimaEdicionProductosListasPrecios) {
                    this.cacheProductosListasPrecios = new Date().getTime();
                    promises.push(productosListasPreciosStore.getProductosListasPreciosFromFirestore());
                  }
                  if (this.cacheMonedas < empresa.ultimaEdicionMonedas) {
                    this.cacheMonedas = new Date().getTime();
                    promises.push(monedasStore.getMonedasFromFirestore());
                  }
                  if (this.cacheMediosPago < empresa.ultimaEdicionMedioPago) {
                    this.cacheMediosPago = new Date().getTime();
                    promises.push(cajasMediosPagoStore.getMediosPagoFromFirestore());
                  }
                  if (this.cacheMediosCobro < empresa.ultimaEdicionMedioCobro) {
                    this.cacheMediosCobro = new Date().getTime();
                    promises.push(cajasMediosCobroStore.getMediosCobroFromFirestore());
                  }
                  if (this.cacheLocales < empresa.ultimaEdicionLocales) {
                    this.cacheLocales = new Date().getTime();
                    promises.push(localStore.getLocalesFromFirestore());
                  }
                  if (this.cacheCentroCostos < empresa.ultimaEdicionCentroCostos) {
                    this.cacheCentroCostos = new Date().getTime();
                    promises.push(centroCostosStore.getCentroCostosFromFirestore());
                  }
                  if (this.cacheCuentaAjena < empresa.ultimaEdicionCuentaAjena) {
                    this.cacheCuentaAjena = new Date().getTime();
                    promises.push(cuentaAjenaStore.getCuentasFromFirestore());
                  }
                  if (this.cachePagoEnPlazos < empresa.ultimaEdicionPagoEnPlazos) {
                    this.cachePagoEnPlazos = new Date().getTime();
                    promises.push(pagoEnPlazosStore.getPagoEnPlazosFromFirestore());
                  }
                  if (this.cacheUsuarios < empresa.ultimaEdicionUsuarios) {
                    this.cacheUsuarios = new Date().getTime();
                    promises.push(usuariosStore.getFromFirestore());
                  }
                  if (this.cacheCajas < empresa.ultimaEdicionCajas) {
                    this.cacheCajas = new Date().getTime();
                    promises.push(cajasStore.getFromFirestore());
                  }
                  this.codigoPromocional = empresa.codigoPromocional;
                  this.opcionesGenerales = empresa.opcionesGenerales;

                  this.opcionesGeneralesFacturacionElectronica = empresa.opcionesGeneralesFacturacionElectronica;
                  this.opcionesGeneralesImpresion = empresa.opcionesGeneralesImpresion;
                  this.opcionesGeneralesServicios = empresa.opcionesGeneralesServicios;
                  this.opcionesGeneralesProductos = empresa.opcionesGeneralesProductos;
                  this.opcionesVenta = empresa.opcionesVenta;
                  this.opcionesGeneralesRegimenExonerado = empresa.opcionesGeneralesRegimenExonerado;
                  this.opcionesGeneralesCamposAdicionales = {
                    campoExtraEntidad:
                      empresa.opcionesGeneralesCamposAdicionales != null &&
                        empresa.opcionesGeneralesCamposAdicionales.campoExtraEntidad != null
                        ? empresa.opcionesGeneralesCamposAdicionales.campoExtraEntidad
                        : [],
                    campoExtraProducto:
                      empresa.opcionesGeneralesCamposAdicionales != null &&
                        empresa.opcionesGeneralesCamposAdicionales.campoExtraProducto != null
                        ? empresa.opcionesGeneralesCamposAdicionales.campoExtraProducto
                        : [],
                    campoExtraServicio:
                      empresa.opcionesGeneralesCamposAdicionales != null &&
                        empresa.opcionesGeneralesCamposAdicionales.campoExtraServicio != null
                        ? empresa.opcionesGeneralesCamposAdicionales.campoExtraServicio
                        : [],
                    campoExtraUsuario:
                      empresa.opcionesGeneralesCamposAdicionales != null &&
                        empresa.opcionesGeneralesCamposAdicionales.campoExtraUsuario != null
                        ? empresa.opcionesGeneralesCamposAdicionales.campoExtraUsuario
                        : []
                  };
                  this.mecanismosEnvioMails = empresa.mecanismosEnvioMails;
                  this.opcionesCompras = {
                    comprobanteContado:
                      empresa.opcionesCompras != null && empresa.opcionesCompras.comprobanteContado != null
                        ? empresa.opcionesCompras.comprobanteContado
                        : false,
                    comprobanteCredito:
                      empresa.opcionesCompras != null && empresa.opcionesCompras.comprobanteCredito != null
                        ? empresa.opcionesCompras.comprobanteCredito
                        : false,
                    controlarCaja:
                      empresa.opcionesCompras != null && empresa.opcionesCompras.controlarCaja != null
                        ? empresa.opcionesCompras.controlarCaja
                        : false,
                    comprobanteDefecto:
                      empresa.opcionesCompras != null && empresa.opcionesCompras.comprobanteDefecto != null
                        ? empresa.opcionesCompras.comprobanteDefecto
                        : false,
                    tasaIVADefecto:
                      empresa.opcionesCompras != null && empresa.opcionesCompras.tasaIVADefecto != null
                        ? empresa.opcionesCompras.tasaIVADefecto
                        : false,
                    vistaPrecioDefecto:
                      empresa.opcionesCompras != null && empresa.opcionesCompras.vistaPrecioDefecto != null
                        ? empresa.opcionesCompras.vistaPrecioDefecto
                        : false,
                    monedaDefecto:
                      empresa.opcionesCompras != null && empresa.opcionesCompras.monedaDefecto != null
                        ? empresa.opcionesCompras.monedaDefecto
                        : false,
                    cantidadDecimales:
                      empresa.opcionesCompras != null && empresa.opcionesCompras.cantidadDecimales != null
                        ? empresa.opcionesCompras.cantidadDecimales
                        : false
                  };

                  if (
                    empresa.opcionesGenerales.integracionHandy != null &&
                    empresa.opcionesGenerales.integracionHandy.habilitado != null &&
                    empresa.opcionesGenerales.integracionHandy.habilitado &&
                    this.handyPOSactual == null
                  ) {
                    if (this.localActual != null && this.handyPOSactual == null) {
                      const posLocalActual = empresa.opcionesGenerales.integracionHandy.pos.find(
                        (pos: any) => this.localActual != null && pos.local == this.localActual.id
                      );
                      this.handyPOSactual = posLocalActual[0];
                    }
                  }
                  if (
                    !("ultimaEdicionCuentaIVA" in empresa) ||
                    !("ultimaEdicionCAE" in empresa) ||
                    !("ultimaEdicionComprasRubros" in empresa) ||
                    !("ultimaEdicionServiciosRubros" in empresa) ||
                    !("ultimaEdicionProductosRubros" in empresa) ||
                    !("ultimaEdicionTasasIVA" in empresa) ||
                    !("ultimaEdicionUnidades" in empresa) ||
                    !("ultimaEdicionServiciosListasPrecios" in empresa) ||
                    !("ultimaEdicionProductosListasPrecios" in empresa) ||
                    !("ultimaEdicionMonedas" in empresa) ||
                    !("ultimaEdicionMedioPago" in empresa) ||
                    !("ultimaEdicionMedioCobro" in empresa) ||
                    !("ultimaEdicionUsuarios" in empresa) ||
                    !("ultimaEdicionCajas" in empresa) ||
                    !("ultimaEdicionCentroCostos" in empresa) ||
                    !("ultimaEdicionCuentaAjena" in empresa) ||
                    !("ultimaEdicionPagoEnPlazos" in empresa) ||
                    !("ultimaEdicionLocales" in empresa)
                  ) {
                    const db = getFirestore();
                    const empresaDocRef = doc(db, "empresas", this.empresa!.id);
                    const time = new Date().getTime();
                    await updateDoc(empresaDocRef, {
                      ultimaEdicionCuentaIVA: time,
                      ultimaEdicionCAE: time,
                      ultimaEdicionComprasRubros: time,
                      ultimaEdicionServiciosRubros: time,
                      ultimaEdicionProductosRubros: time,
                      ultimaEdicionTasasIVA: time,
                      ultimaEdicionUnidades: time,
                      ultimaEdicionServiciosListasPrecios: time,
                      ultimaEdicionProductosListasPrecios: time,
                      ultimaEdicionMonedas: time,
                      ultimaEdicionMedioPago: time,
                      ultimaEdicionMedioCobro: time,
                      ultimaEdicionLocales: time,
                      ultimaEdicionUsuarios: time,
                      ultimaEdicionCajas: time,
                      ultimaEdicionCentroCostos: time,
                      ultimaEdicionCuentaAjena: time,
                      ultimaEdicionPagoEnPlazos: time
                    });
                  }
                  if (empresa.logoURL == "") {
                    this.logoPrincipalURL = "";
                    this.logoPrincipalReferencia = "";
                  } else if (
                    empresa.logoURL != null &&
                    empresa.logoURL != "" &&
                    empresa.logoURL != this.logoPrincipalReferencia
                  ) {
                    try {
                      this.logoPrincipalReferencia = empresa.logoURL;
                      const storage = getStorage();
                      getDownloadURL(ref(storage, this.logoPrincipalReferencia)).then(url => {
                        this.logoPrincipalURL = url;
                      });
                    } catch (e) {

                    }
                  }

                  Promise.all(promises).then(() => {
                    resolve();
                  });
                }
              );
            } catch (error) {
              console.log("Error  obteniendo datos");
              console.log(error);
            }
          }
        } else {
          resolve();
        }
      });
    },
    async checkListenNotificaciones() {
      if (this.suscriberSnapshotNotificaciones == null) {
        if (this.empresa != null) {
          const mensajesStore = useMensajesStore();
          this.suscriberSnapshotNotificaciones = onSnapshot(
            query(collection(getFirestore(), `usuarios/${this.user?.uid}/notificaciones`), where("visto", "==", false)),

            docsResult => {
              docsResult.docs.forEach(doc => {
                const notificacion = doc.data();
                const tipoAviso = notificacion.tipoAviso;
                const titulo = notificacion.titulo;
                const detalle = notificacion.detalle;
                const tipoNotificacion = notificacion.tipoNotificacion;

                if (tipoAviso == "modal") {
                  mensajesStore.mostrarModal(
                    titulo,
                    detalle,
                    tipoNotificacion == "exito" ? mensajesStore.mensajeType.exito : mensajesStore.mensajeType.error
                  );
                }
              });
            }
          );
        }
      }
    },
    setEmpresasRegistradas(empresas: UsuarioEmpresas[]) {
      this.empresasRegistradas = empresas;
    },
    setLocalActual(local: Local) {
      this.localActual = local;
      if (this.handyPOSactual == null) {
        this.handyPOSactual = this.opcionesGenerales.integracionPOSHandy.pos.find(pos => pos.local == local.id) ?? null;
      }
    },
    setMenuState(menuState: boolean) {
      this.menuCollapsed = menuState;
    },
    changeMenuState(menuState: boolean) {
      this.menuCollapsed = !menuState;
      const db = getFirestore();
      const usuarioRef = doc(db, "usuarios", this.user!.uid);
      updateDoc(usuarioRef, {
        menuCollapsed: !menuState
      });
    },
    setDeclaracionBeneficio(declaracion: DeclaracionBeneficio) {
      const year = declaracion.ano;
      const db = getFirestore();
      const empresaDocRef = doc(db, "empresas", this.empresa!.id);

      getDoc(empresaDocRef).then(doc => {
        const empresaData = doc.data();
        const declaracionBeneficioExistente = empresaData?.declaracionBeneficio || {};

        const updateData = {
          declaracionBeneficio: {
            ...declaracionBeneficioExistente,
            [year]: declaracion
          }
        };
        updateDoc(empresaDocRef, updateData);
      });
    },
    setDeclaracionBeneficioHistorico(declaracion: DeclaracionBeneficio) {
      const db = getFirestore();
      const empresaDocRef = doc(db, "empresas", this.empresa!.id);
      getDoc(empresaDocRef).then(doc => {
        const empresaData = doc.data();
        const declaracionBeneficioHistoricoExistente = empresaData?.declaracionBeneficioHistorico || {};
        const declaracionesAnoActual = declaracionBeneficioHistoricoExistente[declaracion.ano] || [];
        declaracionesAnoActual.push(declaracion);
        const updateData = {
          declaracionBeneficioHistorico: {
            ...declaracionBeneficioHistoricoExistente,
            [declaracion.ano]: declaracionesAnoActual
          }
        };
        updateDoc(empresaDocRef, updateData);
      });
    },
    async setOpcionesGenerales(opcion: OpcionesGenerales) {
      const db = getFirestore();
      const empresaDocRef = doc(db, "empresas", this.empresa!.id);
      await updateDoc(empresaDocRef, {
        "opcionesGenerales.tasaIVADefecto": opcion.tasaIVADefecto,
        "opcionesGenerales.centroCostos": opcion.centroCostos,
        "opcionesGenerales.vistaPrecios": opcion.vistaPrecios,
        "opcionesGenerales.monedaDefecto": opcion.monedaDefecto,
        "opcionesGenerales.moduloServicios": opcion.moduloServicios,
        "opcionesGenerales.moduloProductos": opcion.moduloProductos,
        "opcionesGenerales.unidades": opcion.unidades,
        "opcionesGenerales.moduloResguardos": opcion.moduloResguardos,
        "opcionesGenerales.moduloImportador": opcion.moduloImportador,
        "opcionesGenerales.tiposCodigo": opcion.tiposCodigo,
        "opcionesGenerales.codigoImpresion": opcion.codigoImpresion,
        "opcionesGenerales.modificarDecimales": opcion.modificarDecimales,
        "opcionesGenerales.cantidadDecimalesDefecto": opcion.cantidadDecimalesDefecto,
        "opcionesGenerales.topeCuentaCorriente": opcion.topeCuentaCorriente,
        "opcionesGenerales.domicilioEntrega": opcion.domicilioEntrega,
        "opcionesGenerales.moduloCaja": opcion.moduloCaja,
        "opcionesGenerales.moduloRemitos": opcion.moduloRemitos,
        "opcionesGenerales.moduloRemitosSinStock": opcion.moduloRemitosSinStock,
        "opcionesGenerales.medioCobroDefecto": opcion.medioCobroDefecto,
        "opcionesGenerales.medioPagoDefecto": opcion.medioPagoDefecto,
        "opcionesGenerales.integracionAbitab": opcion.integracionAbitab,
        "opcionesGenerales.integracionFEPOS": {
          habilitado: opcion.integracionFEPOS.habilitado,
          listaDePrecioServicios: opcion.integracionFEPOS.listaDePrecioServicios
        },
        "opcionesGenerales.integracionPOSHandy": opcion.integracionPOSHandy,
        "opcionesGenerales.integracionPOSFiserv": opcion.integracionPOSFiserv
      });
      this.opcionesGenerales = opcion;
    },
    setOpcionesHandy(configuracionHandy: IHandyConfig) {
      const db = getFirestore();
      const empresaDocRef = doc(db, "empresas", this.empresa!.id);
      updateDoc(empresaDocRef, {
        "opcionesGenerales.integracionPOSHandy": configuracionHandy
      });
      this.handyPOSactual = configuracionHandy.pos.length > 0 ? configuracionHandy.pos[0] : null;
    },
    setOpcionesFiserv(configuracionFiserv: IFiservConfig) {
      const db = getFirestore();
      const empresaDocRef = doc(db, "empresas", this.empresa!.id);
      updateDoc(empresaDocRef, {
        "opcionesGenerales.integracionPOSFiserv": configuracionFiserv
      });
      this.fiservPOSactual = configuracionFiserv.pos.length > 0 ? configuracionFiserv.pos[0] : null;
    },

    setPOSActual(pos: IHandyPOSConfig) {
      this.handyPOSactual = pos;
    },
    async setOpcionesGeneralesServicios(defecto: OpcionesGeneralesServicios) {
      const empresaDocRef = doc(getFirestore(), "empresas", this.empresa!.id);
      await updateDoc(empresaDocRef, {
        "opcionesGeneralesServicios.nombreModuloServicios": defecto.nombreModuloServicios,
        "opcionesGeneralesServicios.nombreModuloServiciosSingular": defecto.nombreModuloServiciosSingular,
        "opcionesGeneralesServicios.tasaIVADefectoServicios": defecto.tasaIVADefectoServicios,
        "opcionesGeneralesServicios.precioArbitrario": defecto.precioArbitrario
      });
    },
    setOpcionesGeneralesProductos(defecto: OpcionesGeneralesProductos) {
      const empresaDocRef = doc(getFirestore(), "empresas", this.empresa!.id);
      updateDoc(empresaDocRef, {
        "opcionesGeneralesProductos.nombreModuloProductos": defecto.nombreModuloProductos,
        "opcionesGeneralesProductos.nombreModuloProductosSingular": defecto.nombreModuloProductosSingular,
        "opcionesGeneralesProductos.tasaIVADefectoProductos": defecto.tasaIVADefectoProductos,
        "opcionesGeneralesProductos.precioArbitrario": defecto.precioArbitrario
      });
    },
    setOpcionesVentaRol(idRol: string, opciones: OpcionesVentaRol) {
      console.log(opciones);
      const empresaDocRef = doc(getFirestore(), "empresas", this.empresa!.id);
      const updateData = {
        [`opcionesVenta.${idRol}`]: opciones
      };
      return updateDoc(empresaDocRef, updateData);
    },
    setOpcionesGeneralesCamposAdicionales(campos: OpcionesGeneralesCamposAdicionales) {
      const empresaDocRef = doc(getFirestore(), "empresas", this.empresa!.id);
      updateDoc(empresaDocRef, {
        "opcionesGeneralesCamposAdicionales.campoExtraEntidad": campos.campoExtraEntidad,
        "opcionesGeneralesCamposAdicionales.campoExtraProducto": campos.campoExtraProducto,
        "opcionesGeneralesCamposAdicionales.campoExtraServicio": campos.campoExtraServicio,
        "opcionesGeneralesCamposAdicionales.campoExtraUsuario": campos.campoExtraUsuario
      });
    },
    setOpcionesGeneralesImpresion(opciones: OpcionesGeneralesImpresion) {
      const db = getFirestore();
      const empresaDocRef = doc(db, "empresas", this.empresa!.id);
      updateDoc(empresaDocRef, {
        opcionesGeneralesImpresion: opciones
      });
      this.opcionesGeneralesImpresion = opciones;
    },
    setOpcionesGeneralesFacturacionElectronica(opciones: OpcionesGeneralesFacturacionElectronica) {
      const db = getFirestore();
      const empresaDocRef = doc(db, "empresas", this.empresa!.id);
      updateDoc(empresaDocRef, {
        opcionesGeneralesFacturacionElectronica: opciones
      });
    },
    setMecanismosEnvioMail(opciones: MecanismosEnvioMails[]) {
      const db = getFirestore();
      const empresaDocRef = doc(db, "empresas", this.empresa!.id);
      updateDoc(empresaDocRef, {
        mecanismosEnvioMails: opciones
      });
      this.mecanismosEnvioMails = opciones;
    },
    setOpcionesCompras(opciones: OpcionesCompras) {
      const empresaDocRef = doc(getFirestore(), "empresas", this.empresa!.id);
      updateDoc(empresaDocRef, {
        opcionesCompras: opciones
      });
    },
    setOpcionesGeneralesRegimenExonerado(opciones: OpcionesGeneralesRegimenExonerado) {
      const empresaDocRef = doc(getFirestore(), "empresas", this.empresa!.id);
      updateDoc(empresaDocRef, {
        opcionesGeneralesRegimenExonerado: opciones
      });
    },
    vaciarDatos() {
      this.chatMensajes = [];
      this.arrayNotificaciones = [];

      this.localActual = null;
      this.cacheUsuarios = 0;
      this.cacheComprasRubros = 0;
      this.cacheCuentaIVA = 0;
      this.cacheCAE = 0;
      this.cacheServiciosRubros = 0;
      this.cacheProductosRurbos = 0;
      this.cacheTasasIVA = 0;
      this.cacheServiciosListasPrecios = 0;
      this.cacheProductosListasPrecios = 0;
      this.cacheCentroCostos = 0;
      this.cacheCuentaAjena = 0;
      this.cachePagoEnPlazos = 0;
      this.cacheMonedas = 0;
      this.cacheLocales = 0;
      this.cacheUnidades = 0;
      this.cacheMediosPago = 0;
      this.cacheMediosCobro = 0;
      this.cacheCajas = 0;
      this.mecanismosEnvioMails = [];
      this.opcionesGenerales = opcionesGeneralesDefecto;
      this.opcionesGeneralesFacturacionElectronica = opcionesGeneralesFacturacionElectronicaDefecto;
      this.opcionesGeneralesServicios = {
        nombreModuloServicios: "",
        nombreModuloServiciosSingular: "",
        tasaIVADefectoServicios: "",
        precioArbitrario: false
      };
      this.opcionesGeneralesProductos = {
        nombreModuloProductos: "",
        nombreModuloProductosSingular: "",
        tasaIVADefectoProductos: "",
        precioArbitrario: false
      };
      this.opcionesCompras = opcionesCompraDefecto;
      if (this.suscriberSnapshotEmpresa != null) {
        this.suscriberSnapshotEmpresa();
        this.suscriberSnapshotEmpresa = null;
      }
      this.opcionesGeneralesRegimenExonerado = {
        esContribuyenteRegimenExonerado: false,
        leyendaRegimenExonerado: "",
        leyendaEncabezadoRegimenExonerado: ""
      };
      this.opcionesGeneralesImpresion = opcionesGeneralesImpresionDefecto;
      if (this.suscriberSnapshotNotificaciones != null) {
        this.suscriberSnapshotNotificaciones();
        this.suscriberSnapshotNotificaciones = null;
      }
      this.logoPrincipalReferencia = "";
      this.logoPrincipalURL = "";
      this.logoSucursalReferencia = "";
      this.logoSucursalURL = "";
      this.pendientePagoFacturas = [];
      this.pendientePagoSaldo = 0;
      this.pendientePagoDiasFacturaMasAntigua = 0;

      const comprasRubroStore = useComprasRubrosStore();
      comprasRubroStore.$reset();
      const caeStore = useCAEStore();
      caeStore.$reset();
      const cuentaIVAStore = useCuentaIVAStore();
      cuentaIVAStore.$reset();
      const serviciosRubroStore = useServiciosRubrosStore();
      serviciosRubroStore.$reset();
      const productosRubroStore = useProductosRubrosStore();
      productosRubroStore.$reset();
      const tasasIVAStore = useTasasIVAStore();
      tasasIVAStore.$reset();
      const serviciosListasPreciosStore = useServiciosListasPreciosStore();
      serviciosListasPreciosStore.$reset();
      const productosListasPreciosStore = useProductosListasPreciosStore();
      productosListasPreciosStore.$reset();
      const monedasStore = useMonedasStore();
      monedasStore.$reset();
      const cajasMediosPagoStore = useCajasMediosPagoStore();
      cajasMediosPagoStore.$reset();
      const cajasMediosCobroStore = useCajasMediosCobroStore();
      cajasMediosCobroStore.$reset();
      const localStore = useLocalesStore();
      localStore.$reset();
      const unidadesStore = useUnidadesStore();
      unidadesStore.$reset();
      const usuariosStore = useUsuariosStore();
      usuariosStore.$reset();
      const cajasStore = useCajasStore();
      cajasStore.$reset();
      const unidadStore = useUnidadesStore();
      unidadStore.$reset();
      const centroCostosStore = useCentroCostosStore();
      centroCostosStore.$reset();
      const cuentaAjenaStore = useCuentaAjenaStore();
      cuentaAjenaStore.$reset();
      const pagoEnPlazosStore = usePagoEnPlazosStore();
      pagoEnPlazosStore.$reset();
      const cotizacionesStore = useCotizacionesStore();
      cotizacionesStore.$reset();
      const cotizacionesBCUStore = useCotizacionesBCUStore();
      cotizacionesBCUStore.$reset();
      const serviciosCacheStore = useServiciosCacheStore();
      serviciosCacheStore.$reset();
      const productosCacheStore = useProductosCacheStore();
      productosCacheStore.$reset();
    },
    signOut() {
      this.userLoggedIn = null;
      this.token = null;
      this.empresa = null;
      this.empresasRegistradas = [];
      if (this.suscriberSnapshotEmpresa != null) {
        this.suscriberSnapshotEmpresa();
        this.suscriberSnapshotEmpresa = null;
      }
      if (this.suscriberSnapshotNotificaciones != null) {
        this.suscriberSnapshotNotificaciones();
        this.suscriberSnapshotNotificaciones = null;
      }
      if (this.suscriberSnapshotUsuario != null) {
        this.suscriberSnapshotUsuario();
        this.suscriberSnapshotUsuario = null;
      }
      if (this.suscriberChatMensajes != null) {
        this.suscriberChatMensajes();
        this.suscriberChatMensajes = null;
      }
      router.push("/login");
      this.vaciarDatos();
    }
  }
});
